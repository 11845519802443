/* eslint-disable no-undef */
/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Slide from '@material-ui/core/Slide';
import WebMenu from './web.menu';
import MobileMenu from './mobile.menu';
import Logo from '../../images/logo.png';

function HideOnScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

const tabs = [
  {
    name: 'Home',
    href: '/',
  },
  {
    name: 'About Us',
    href: '/about',
  },
  {
    name: 'Values',
    href: '/values',
  },
  {
    name: 'Services',
    href: '/services',
  },
  {
    name: 'Links & Blogs',
    href: '/useful-links',
  },
  {
    name: 'Login',
    href: 'https://bandk.caoasoftware.com/',
    external: true,
  },
];

const Header = (props) => {
  const useStyles = makeStyles(() => ({
    root: {
      flexGrow: 1,
    },
    logo: {
      maxHeight: '10vh',
      maxWidth: '50vw',
      paddingBottom: '5px',
    },
    appBar: {
      left: 0,
      maxWidth: '100vw',
      backgroundImage: 'linear-gradient(to left, #1FA2FF, #12D8FA, #A6FFCB)',
    },
  }));
  const [isSmallDevice, setSmallDevice] = useState(false);

  useEffect(() => {
    setSmallDevice(window.innerWidth <= 760);
  });

  const classes = useStyles();
  return (
    <>
      <HideOnScroll {...props}>
        <AppBar className={classes.appBar} elevation={0}>
          <Toolbar>
            <Grid container spacing={3}>
              <Grid item xs={8}>
                <img src={Logo} alt="logo" className={classes.logo} />
              </Grid>
            </Grid>
            {isSmallDevice && <MobileMenu direction="top" tabs={tabs} />}
            {!isSmallDevice && <WebMenu tabs={tabs} />}
          </Toolbar>
        </AppBar>
      </HideOnScroll>
    </>
  );
};

export default Header;
