import React from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import BusinessIcon from '@material-ui/icons/Business';
import EmailIcon from '@material-ui/icons/Email';
import SmartphoneIcon from '@material-ui/icons/Smartphone';
import PhoneIcon from '@material-ui/icons/Phone';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import CopyrightIcon from '@material-ui/icons/Copyright';
import MapIcon from '@material-ui/icons/Map';
import Typography from '../Typography';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    margin: 0,
    padding: 0,
  },
  footerCopyRights: {
    backgroundColor: '#161B27',
    height: '50px',
  },
  footerContent: {
    flexGrow: 1,
    backgroundColor: '#1B2231',
  },
  footerSocial: {
    backgroundColor: '#3AA4FB',
    flexGrow: 1,
  },
  copyrightText: {
    padding: '20px',
    color: 'white',
  },
  socialText: {
    color: 'white',
    paddingLeft: '15px',
  },
  contactText: {
    padding: '1vh',
  },
  contactHeader: {
    padding: '1vh',
    color: 'white',
    paddingTop: '6vh',
  },
  socialIcons: {
    color: 'white',
    paddingLeft: '15px',
  },
  copyRightHeader: {
    color: 'grey',
  },
}));

const Footer = () => {
  const classes = useStyles();

  const handleCLick = () => {
    const location = 'https://www.google.com/maps/place/BHAGAT+%26+KUMAR/@13.0590646,77.5815545,15z/data=!4m5!3m4!1s0x0:0x3b69968d8a3aea23!8m2!3d13.0590646!4d77.5815545';
    // eslint-disable-next-line no-undef
    const link = document.createElement('a');
    link.href = location;
    link.setAttribute('target', '_blank');
    link.setAttribute('rel', 'noopener noreferrer');
    // eslint-disable-next-line no-undef
    document.body.appendChild(link);
    link.click();
  };
  return (
    <Container maxWidth={false} className={classes.root}>
      <section className={classes.footerSocial}>
        <Grid container className={classes.root} spacing={2}>
          <Grid item xs={12} md={10}>
            <Typography className={classes.socialText}>
              Get connected with us on social networks!
            </Typography>
          </Grid>
          <Grid item xs={12} md={2}>
            <span className={classes.socialIcons}>
              <span style={{ paddingRight: '1vw' }}>
                <FacebookIcon />
              </span>
              <span style={{ paddingRight: '1vw' }}>
                <MapIcon onClick={handleCLick} />
              </span>
              <span>
                <LinkedInIcon />
              </span>
            </span>
          </Grid>
        </Grid>
      </section>
      <section className={classes.footerContent}>
        <Grid container className={classes.root} spacing={2}>
          <Grid item xs={12} md={4}>
            <Typography
              variant="h3"
              align="center"
              className={classes.contactHeader}
              marked="center"
            >
              Contact Us
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <section className={classes.copyrightText}>
              <Typography variant="h6" align="center" marked="center">
                <span>Bengaluru</span>
              </Typography>
              <Typography style={{ paddingTop: '5px' }}>
                <BusinessIcon />
                <span style={{ paddingLeft: '5px' }}>
                  No: 146/20, 1st floor, Kodigehalli Main Road, Sahakarnagar,
                  Bengaluru-560092
                </span>
              </Typography>
              <Typography style={{ paddingTop: '10px' }}>
                <EmailIcon />
                <span style={{ paddingLeft: '5px' }}>
                  bhagatandkumar@gmail.com
                </span>
              </Typography>
              <Typography style={{ paddingTop: '10px' }}>
                <PhoneIcon />
                <span style={{ paddingLeft: '5px' }}>+080-41162297</span>
              </Typography>
              <Typography style={{ paddingTop: '10px' }}>
                <SmartphoneIcon />
                <span style={{ paddingLeft: '5px' }}>+91 9164793621</span>
              </Typography>
            </section>
          </Grid>
          <Grid item xs={12} md={4}>
            <section className={classes.copyrightText}>
              <Typography variant="h6" align="center" marked="center">
                <span>Mangaluru</span>
              </Typography>
              <Typography style={{ paddingTop: '5px' }}>
                <BusinessIcon />
                <span style={{ paddingLeft: '5px' }}>
                  Goveas Commercial Complex, 2nd floor, opposite Roshni Nilaya
                  College, Kankanady, Mangalore-575002
                </span>
              </Typography>
              <Typography style={{ paddingTop: '10px' }}>
                <EmailIcon />
                <span style={{ paddingLeft: '5px' }}>
                  bhagatandkumar@gmail.com
                </span>
              </Typography>
              <Typography style={{ paddingTop: '10px' }}>
                <PhoneIcon />
                <span style={{ paddingLeft: '5px' }}>+0824-4111174</span>
              </Typography>
              <Typography style={{ paddingTop: '10px' }}>
                <SmartphoneIcon />
                <span style={{ paddingLeft: '5px' }}>+91 9591349783</span>
              </Typography>
            </section>
          </Grid>
        </Grid>
      </section>
      <section className={classes.footerCopyRights}>
        <Typography className={classes.copyrightText} align="center">
          <span className={classes.copyRightHeader}>
            <CopyrightIcon />
            2020 Copyright
          </span>
          : bhagatandkumar.com
        </Typography>
      </section>
    </Container>
  );
};

export default Footer;
